import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs'; // Import dayjs for date handling
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { useSelector } from 'react-redux';
import submitFormData from '../../utils/submitFormData';
import { FormData } from '../../pages/Videos';
import { RootState } from '../../store'; // Import your Redux store types

dayjs.extend(relativeTime);
dayjs.extend(utc); // To handle UTC properly

interface DeviceStatus {
  [device_id: string]: 'Online' | 'Offline';
}

interface DeviceStatusProps {
  setVehicles: Dispatch<SetStateAction<string[]>>;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  formData: FormData;
}

interface LatestUpload {
  [device_id: string]: string | 'No Uploads';
}

interface DeviceInfo {
  status: 'Online' | 'Offline';
  id: string;
}

const DeviceStatusIndicator: React.FC<DeviceStatusProps> = ({ setVehicles, setFormData, formData }) => {
  const [deviceStatuses, setDeviceStatuses] = useState<DeviceStatus>({});
  const [isLoading, setIsLoading] = useState(true);
  const [latestUpload, setLatestUpload] = useState<LatestUpload>({});
  const [refreshDeviceStatus, setRefreshDeviceStatus] = useState(1);
  const [deviceInfos, setDeviceInfos] = useState<{ [deviceId: string]: DeviceInfo }>({});
  
  // Get the JWT token from the Redux store
  const token = useSelector((state: RootState) => state.auth.token);

  // Sort devices by status (Online first, then Offline)
  function orderDeviceStatus(statusMap: DeviceStatus) {
    const entries = Object.entries(statusMap);
    entries.sort((a, b) => {
      if (a[1] === 'Online' && b[1] === 'Offline') return -1;
      if (a[1] === 'Offline' && b[1] === 'Online') return 1;
      return 0;
    });
    return Object.fromEntries(entries);
  }
  

  useEffect(() => {
    const fetchDeviceStatuses = async () => {
      setIsLoading(true);
      try {
        if (refreshDeviceStatus !== 0) {
          // Add Authorization header with the JWT token for this request
          const response = await axios.get<{ devices: { [deviceId: string]: DeviceInfo } }>(
            `${process.env.REACT_APP_BACKEND_URL}/socketxp-status`, 
            {
              headers: {
                'Content-Type': 'application/json',
              },
              withCredentials: true, // Ensure cookies are sent with the request
            }
          );
  
          const devices = response.data.devices;
  
          // Ensure correct typing and retain object mapping with proper inference
          const newDeviceStatuses: DeviceStatus = Object.entries(devices).reduce(
            (acc: DeviceStatus, [deviceId, deviceInfo]) => {
              acc[deviceId] = deviceInfo.status;
              return acc;
            },
            {} as DeviceStatus
          );
  
          // Sort the device statuses by online/offline
          const sortedDeviceStatuses = orderDeviceStatus(newDeviceStatuses);
          setDeviceStatuses(sortedDeviceStatuses);
          setDeviceInfos(devices);
          setVehicles(['All'].concat(Object.keys(devices)));
  
          // Use the submitFormData utility and pass in the token for Authorization
          const latest_upload = await submitFormData({ vehicles: Object.keys(devices) }, 'latest-upload');
          setLatestUpload(latest_upload['latest_upload']);
        }
      } catch (error) {
        console.error('Error fetching device statuses', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchDeviceStatuses();
  }, [refreshDeviceStatus, token]); // Include token as a dependency to re-run if it changes

  function handleRefreshDeviceStatus() {
    setRefreshDeviceStatus(refreshDeviceStatus * -1);
  }

  function handleRightClick(id: string) {
    window.open(`https://portal.socketxp.com/#/ssh/${id}`, '_blank');
  }

  // Helper function to format the latest upload time or display "No Uploads"
  const renderLatestUpload = (deviceId: string) => {
    const uploadTime = latestUpload[deviceId];
    if (uploadTime === 'No Uploads') {
      return 'No Uploads For > 24 Hours';
    }
    if (uploadTime) {
      // Parse the timestamp and return a formatted relative time (e.g., "5 minutes ago")
      return `Last uploaded ${dayjs(uploadTime).fromNow()}`;
    }
    return 'No Uploads For > 24 Hours';
  };

  return (
    <div className="p-4 border rounded-lg shadow-md w-fit h-fit bg-white">
      <div className="flex flex-row justify-between w-full h-fit">
        <h2 className="text-lg font-semibold mb-4">Device Status</h2>
        {!isLoading && (
          <img
            onClick={handleRefreshDeviceStatus}
            className="h-[30px] mr-[15px] hover:cursor-pointer"
            src={'https://upload.wikimedia.org/wikipedia/commons/b/bc/Refresh_icon.png'}
            alt="Refresh"
          />
        )}
      </div>
      {isLoading ? (
        <div className="flex justify-center">
          <img
            height="40"
            width="40"
            src={'https://media.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif'}
            alt="Loading..."
          />
        </div>
      ) : (
        <ul className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">
          {Object.entries(deviceStatuses).map(([deviceId, status]) => (
            <div
              key={deviceId}
              onClick={() => setFormData({ ...formData, vehicle: [deviceId] })}
              onContextMenu={() => handleRightClick(deviceInfos[deviceId]?.id)}
              className="flex flex-col h-fit w-full hover:bg-gray-100 hover:cursor-pointer rounded-lg"
            >
              <li className="p-2 flex flex-row space-x-5 justify-between items-center">
                <div className="flex flex-col">
                  <span className="font-medium">{deviceId}</span>
                </div>
                <span
                  className={`px-2 py-1 rounded-full text-sm ${
                    status === 'Online' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}
                >
                  {status}
                </span>
              </li>
              <span className="px-2 rounded-full text-xs text-[#00000080]">
                {renderLatestUpload(deviceId)} {/* Display formatted latest upload or "No Uploads" */}
              </span>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DeviceStatusIndicator;
