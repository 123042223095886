import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null); // null initially indicates loading
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/verify-token`, {
          method: 'POST',
          credentials: 'include', // Include cookies in the request
        });

        if (response.ok) {
          setIsAuthenticated(true);  // User is authenticated
        } else if (response.status === 401) {
          setIsAuthenticated(false);  // Not authenticated, redirect to login
        } else {
          setError('Unexpected error occurred'); // Handle other status codes appropriately
        }
      } catch (error) {
        setError('Failed to verify authentication. Please try again.');
      }
    };

    checkAuthStatus();
  }, []);

  // If still loading, show a loading state
  if (isAuthenticated === null) {
    return <div>Loading...</div>; // You could replace this with a spinner component
  }

  // Handle any potential errors during authentication check
  if (error) {
    return <div>{error}</div>;
  }

  // If user is not authenticated, redirect to login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Render the protected component if authenticated
  return children;
};

export default ProtectedRoute;
