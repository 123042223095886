import React from 'react';
import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';


const Dashboard: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to determine if a link is active
  const isActive = (path: string) => location.pathname === path;

  const handleLogout = async () => {
    try {
      // Make an API request to log out (clear cookies, etc.)
      const response = await fetch(`${process.env.REACT_APP_BACKEND_BASE_URL}/logout`, {
        method: 'POST',
        credentials: 'include', // Ensure cookies are included in the request
      });

      if (response.ok) {
        // Redirect to login page after successful logout
        navigate('/login');
      } else {
        console.error('Failed to log out');
      }
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <nav className="bg-gray-800 p-4">
        <div className='flex flex-row justify-between w-full'>
          <ul className="flex flex-row space-x-6 w-fit">
            <li>
              <Link
                to="/dashboard/home"
                className={`text-white text-lg ${
                  isActive('/dashboard/home') ? 'font-bold underline' : 'hover:underline'
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/dashboard/videos"
                className={`text-white text-lg ${
                  isActive('/dashboard/videos') ? 'font-bold underline' : 'hover:underline'
                }`}
              >
                Videos
              </Link>
            </li>
          </ul>
          <ul>
            {/* Logout button */}
            <li className="mr-6">
              <button
                onClick={handleLogout}
                className="text-white text-lg hover:underline"
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      </nav>

      <div className="p-6 bg-gray-100 flex-grow">
        <Outlet /> {/* Nested routes (Home/Videos) will render here */}
      </div>

      <footer className="bg-gray-800 text-white text-xs p-2 text-right">
        Data Portal v1.0 - Oct 2024
      </footer>
    </div>
  );
};

export default Dashboard;

